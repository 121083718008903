<template>
	<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" width="579px" :title="diaLogTitle" :visible.sync="diaLogVisible">
		<div v-if="taskType != 'monthviewClassTaskEdit'" class="handle_form">
			<el-form v-for="(item,index) in taskForm.taskList" :key="index" ref="taskForm" :rules="taskRules"
				:model="taskForm">
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item :rules="taskRules.finishTime" :prop="`taskList.${index}.finishTime`"
							class="flex_item" label="任务周期">
							<el-date-picker @change="dateChange($event, index)" v-model="item.finishTime"
								:picker-options="pickerOptions" class="handle_form_date" type="daterange"
								range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item :rules="taskRules.title" :prop="`taskList.${index}.title`" class="flex_item"
							label="任务标题">
							<input v-model="item.title" class="handle_form_ipt" placeholder="请输入" />
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item :rules="taskRules.content" :prop="`taskList.${index}.content`" class=""
							label="任务内容">
							<textarea v-model="item.content" class="handle_form_textarea" placeholder="请输入" />
						</el-form-item>
					</div>
				</div>
				<div v-if="taskType == 'monthViewsTaskAdd'" class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item :rules="taskRules.viewClassId" :prop="`taskList.${index}.viewClassId`"
							class="flex_item" label="任务归属:">
							<el-select class="handle_form_select" v-model="item.viewClassId" placeholder="请选择">
								<el-option-group v-for="itemOne in belongList" :key="itemOne.label"
									:label="itemOne.label">
									<el-option v-for="itemTwo in itemOne.options" :key="itemTwo.viewClassId"
										:label="itemTwo.className" :value="itemTwo.viewClassId">
									</el-option>
								</el-option-group>
							</el-select>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="附件:">
							<uploadFile :fileNum="index" :showReUpload="false" @successUpload="taskUpload"
								:fileSrc="item.attPath?item.attPath:''" :fileNameP="item.attNamq?item.attNamq:''"/>
						</el-form-item>
						<i @click="deleteFile(2, index)" v-if="item.attPath" class="handle_form_delete el-icon-delete"></i>
					</div>
				</div>
			</el-form>
			<div class="handle_form_row">
				<div @click="addTask" class="handle_form_add">
					+ 继续添加任务
				</div>
			</div>
			<div class="handle_form_btn">
				<span @click="closeDialog" class="handle_form_cancel">取消</span>
				<span v-preventReClick @click="submitDialog" class="handle_form_confirm">确定</span>
			</div>
		</div>
		<div v-else class="handle_form">
			<el-form ref="editTaskForm" :rules="taskRules" :model="editTaskForm">
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item prop="finishEditTime" class="flex_item" label="任务周期">
							<el-date-picker :disabled="canEdit" :clearable="false" @blur="dateChange($event)"
								v-model="editTaskForm.finishEditTime" :picker-options="pickerOptions"
								class="handle_form_date" type="daterange" range-separator="至" start-placeholder="开始日期"
								end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item prop="title" class="flex_item" label="任务标题">
							<input :disabled="canEdit" v-model="editTaskForm.title" class="handle_form_ipt"
								placeholder="请输入" />
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item prop="content" class="" label="任务内容">
							<textarea :disabled="canEdit" v-model="editTaskForm.content" class="handle_form_textarea"
								placeholder="请输入" />
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" prop="status" label="任务状态:">
							<el-select v-model="editTaskForm.status" :disabled="canEdit" class="handle_form_select"
								:clearable="false" placeholder="请选择">
								<el-option v-for="item in statusList" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
				</div>
				<div v-if="(editTaskForm.attPath&&editTaskForm.status == 2) || (editTaskForm.status == 1)" class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="任务附件:" prop="">
							<uploadFile :showReUpload="false" @successUpload="taskUpload"
								:fileSrc="editTaskForm.attPath?editTaskForm.attPath:''" :fileNameP="editTaskForm.attNamq?editTaskForm.attNamq:''"/>
						</el-form-item>
						<i @click="deleteFile(1)" v-if="editTaskForm.attPath&&editTaskForm.status == 1" class="handle_form_delete el-icon-delete"></i>
					</div>
				</div>
				<!-- <div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item prop="" class="flex_column" label="任务备注">
							<textarea :disabled="canEdit" v-model="editTaskForm.mark" class="handle_form_textarea"
								placeholder="请输入" />
						</el-form-item>
					</div>
				</div> -->
				<div class="handle_form_row" v-if="editTaskForm.status == 2&&canEdit&&editTaskForm.replyAtts&&editTaskForm.replyAtts.length > 0">
					<div class="handle_form_item">
						<el-form-item class="" label="学生附件:">
							<div class="handle_form_upLoad" v-for="(itemT,indexT) in editTaskForm.replyAtts" :key="indexT" >
								<uploadFile :showReUpload="false" :fileSrc="itemT.replyattPath?itemT.replyattPath:''" :fileNameP="itemT.replyattNamq?itemT.replyattNamq:''"/>
							</div>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row" v-if="editTaskForm.status == 2&&canEdit&&editTaskForm.replyRemark">
					<div class="handle_form_item">
						<el-form-item prop="" class="" label="学生备注">
							<textarea :disabled="canEdit" v-model="editTaskForm.replyRemark" class="handle_form_textarea"
								placeholder="请输入" />
						</el-form-item>
					</div>
				</div>
			</el-form>
			<div class="handle_form_btn">
				<span @click="closeDialog" class="handle_form_cancel">取消</span>
				<span v-preventReClick v-if="!canEdit" @click="submitDialog" class="handle_form_confirm">确定</span>
			</div>
			<!-- <div v-if="diaLogTitle != '任务详情'||editTaskForm.status==1" class="handle_form_btn">
				<span @click="closeDialog" class="handle_form_cancel">取消</span>
				<span v-preventReClick v-if="!canEdit" @click="submitDialog" class="handle_form_confirm">确定</span>
			</div> -->
		</div>
	</el-dialog>
</template>

<script>
	import Bus from "./eventBus.js";
	import uploadFile from "@/components/uploadFile/index.vue";
	import commonFn from "@/utils/common.js";
	import {
		addAllViewsTask,
		strategyAllViews,
		classifyAllViews,
		editViewTask,
		detailViewsTask,
	} from "@/api/taskProgress"
	import {
		mapActions
	} from 'vuex'
	export default {
		components: {
			uploadFile
		},
		props: {
			studentId: {
				type: String,
				default: function() {
					return ""
				}
			},
			badgeId: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		data() {
			// const checkTime = (rule, value, callback) => {
			// 	console.log("finishEditTime", value)
			// 	if (!value) {
			// 		if (!this.editTaskForm.completeDateStart || !this.editTaskForm.completeDateEnd) {
			// 			callback(new Error("请选择任务完成时间"));
			// 		}
			// 	} else {
			// 		callback();
			// 	}
			// 	callback();
			// };
			const checkAttPath = (rule, value, callback) => {
				console.log("请上传附件", this.editTaskForm.attPath)
				if (!this.editTaskForm.attPath) {
					callback(new Error("请上传附件"));
				} else {
					callback();
				}
				callback();
			};
			return {
				diaLogTitle: "",
				diaLogVisible: false,
				taskRules: {
					finishTime: [{
						required: true,
						message: '请选择任务完成时间',
						trigger: 'change'
					}],
					finishEditTime: [{
						required: true,
						// validator: checkTime,
						message: '请选择任务完成时间',
						trigger: 'change'
					}],
					title: [{
						required: true,
						message: '请输入任务标题',
						trigger: 'change'
					}],
					content: [{
						required: true,
						message: '请输入任务内容',
						trigger: 'change'
					}],
					viewClassId: [{
						required: true,
						message: '请选择任务归属',
						trigger: 'change'
					}],
					status: [{
						required: true,
						message: '请选择任务状态',
						trigger: 'change'
					}],
					attPath: [{
						required: true,
						validator: checkAttPath,
						trigger: 'change'
					}]
				},
				taskSection: [],
				pickerOptions: {},
				// pickerOptions: {
				// 	disabledDate: time => {
				// 		let day = 1 * 24 * 3600 * 1000 - 1
				// 		let maxTime = new Date("2022-05-07").getTime();
				// 		let minTime = new Date("2022-05-03").getTime() - day;
				// 		return time.getTime() < minTime || time.getTime() > maxTime;
				// 		// // 只能选择当前 1 年内的时间
				// 		// const year = 365 * 24 * 3600 * 1000;
				// 		// // 只能选择当前 1 月的时间
				// 		// const month = 30 * 24 * 3600 * 1000;
				// 		// // 只能选择当前 1 周的时间
				// 		// const week = 7 * 24 * 3600 * 1000;
				// 		// // 返回小于当前日期并近【1年】【1月】【1周】内的日期
				// 		// // 注意：这是从当前日期开始往前算，如果当前日期可选的话，不要写【-8.64e7】
				// 		// return time.getTime() > Date.now() || time.getTime() < (Date.now() - 8.64e7) - year;
				// 		// // 禁用今天之前的日期【当前天可选】
				// 		// return time.getTime() < Date.now() - 8.64e7;
				// 		// // 禁用今天之前的日期【当前天不可选】
				// 		// return time.getTime() < Date.now();
				// 		// // 禁用今天之后的日期【当前天不可选】
				// 		// return time.getTime() > Date.now() - 8.64e7;
				// 		// // 禁用今天之后的日期【当前天可选】
				// 		// return time.getTime() > Date.now();
				// 	}
				// },
				taskForm: {
					taskList: []
				},
				taskProps: {},
				taskType: "",
				belongList: [],
				editTaskForm: {},
				canEdit: false,
				statusList: [{
						label: "未完成",
						value: 1
					},
					{
						label: "已完成",
						value: 2
					}
				],
			}
		},
		mounted() {
			// Bus.$on("monthviewTableHandle", props => {
			// 	console.log("monthviewTableHandle>>>双击", props)
			// 	this.editTaskForm = {}
			// 	this.editTaskForm = props
			// 	this.diaLogTitle = "任务详情"
			// 	this.diaLogVisible = true
			// })
		},
		methods: {
			...mapActions(['badgeNumClear']),
			// 获取任务详情
			viewsTaskDetail() {
				this.canEdit = false
				detailViewsTask({
					viewTaskId: this.studentId
				}).then((res) => {
					if (res.code == 0 && res.data) {
						this.editTaskForm = res.data
						if (res.data.status == 2) {
							this.canEdit = true
						}
						// console.log("editTaskForm2", this.editTaskForm)
						this.editTaskForm.finishEditTime = [res.data.completeDateStart, res.data
							.completeDateEnd
						]
						console.log("badgeId", this.badgeId)
						if (this.badgeId) {
							this.badgeNumClear(this.badgeId, {
								id: this.badgeId
							}).then((res) => {
								// console.log("res", res)
								if (res.code == 0) {
									this.$emit("clearBadgeNum", )
								}
							})
						}
						this.$forceUpdate()
					}
				})
			},
			// 获取策略数据
			allViewsStrategy() {
				return new Promise((resolve, reject) => {
					let params = {
						studentInfoId: this.studentId,
					}
					strategyAllViews(params).then((res) => {
						if (res.code == 0) {
							let strategys = []
							if (res.data && res.data.length > 0) {
								strategys = res.data
								resolve(strategys)
							} else {
								resolve(strategys)
							}
						} else {
							reject()
						}
					})
				})
			},
			// 获取分类数据
			async allViewsClassify(intDate) {
				return new Promise((resolve, reject) => {
					let params = {
						studentInfoId: this.studentId,
						year: intDate.year,
						month: intDate.month,
					}
					classifyAllViews(params).then(res => {
						if (res.code == 0) {
							if (res.data && res.data.length > 0) {
								let resData = res.data
								resolve(resData)
							} else {
								resolve([])
							}
						} else {
							reject()
						}
					})
				})
			},
			// 将分类按照相同策略id分组
			async groupIntoClassify(intData) {
				return new Promise((resolve, reject) => {
					let hasStrategyId = intData.filter(item => {
						return item.viewStrategyId
					})
					// console.log("groupIntoClassify1", hasStrategyId)
					if (hasStrategyId && hasStrategyId.length > 0) {
						let keys = hasStrategyId.map((item) => {
							if (item.viewStrategyId) {
								return item.viewStrategyId;
							}
						});
						keys = [...new Set(keys)];
						console.log("groupIntoClassify2", keys)
						let result = {};
						keys.forEach((item) => {
							result[item] = [];
						});
						hasStrategyId.map((item) => {
							keys.some((value) => {
								if (item.viewStrategyId == value) {
									result[value].push(item);
									return;
								}
							});
						});
						console.log("groupIntoClassify3", result)
						resolve(result)
					} else {
						resolve([])
					}
				})
			},
			// 获取归属数据
			async belongData(intDate) {
				let strategy = await this.allViewsStrategy()
				// console.log("获取策略数据", strategy)
				let classifys = await this.allViewsClassify(intDate)
				// console.log("获取分类数据2", classifys)
				let groupIntoClass = await this.groupIntoClassify(classifys)
				this.belongList = []
				strategy.forEach(item => {
					this.belongList.push({
						label: item.strategyName,
						options: groupIntoClass[item.viewStrategyId]
					})
				})
				console.log("获取最终数据", this.belongList)
			},
			// 初始化数据
			originData() {
				return new Promise((resolve, reject) => {
					let originArr = ["studentAccountId", "viewClassId", "viewTargetId"]
					let originObj = {
						colour: "",
						sendStatus: 2,
						status: 1,
					}
					originArr.forEach(item => {
						this.$set(originObj, item, this.taskProps[item] ?
							this.taskProps[item] : "")
					})
					if (this.taskType == "monthViewsTaskAdd") {
						originObj.studentAccountId = this.studentId
					}
					resolve(originObj)
				})
			},
			// 日期选择
			dateChange(e, index) {
				if (this.taskType != "monthviewClassTaskEdit") {
					if (e && e.length > 0) {
						let start = commonFn.timeFormat(e[0], "yyyy-MM-dd") + " " +
							"00:00:00"
						let end = commonFn.timeFormat(e[1], "yyyy-MM-dd") + " " +
							"00:00:00"
						this.taskForm.taskList[index].completeDateStart = start
						this.taskForm.taskList[index].completeDateEnd = end
					} else {
						this.taskForm.taskList[index].completeDateStart = ""
						this.taskForm.taskList[index].completeDateEnd = ""
					}
				} else {
					console.log("dateChange-edit", this.editTaskForm.finishEditTime)
					let start = commonFn.timeFormat(this.editTaskForm.finishEditTime[0], "yyyy-MM-dd") + " " +
						"00:00:00"
					let end = commonFn.timeFormat(this.editTaskForm.finishEditTime[1], "yyyy-MM-dd") + " " +
						"00:00:00"
					this.editTaskForm.completeDateStart = start
					this.editTaskForm.completeDateEnd = end
					this.$forceUpdate()
				}
			},
			deleteFile(num, index) {
				if(num == 1) {
					this.editTaskForm.attPath = ""
					this.$forceUpdate()
				} else {
					this.taskForm.taskList[index].attNamq = ""
					this.taskForm.taskList[index].attPath = ""
					this.$forceUpdate()
				}
			},
			taskUpload(props) {
				console.log("taskUpload", props, this.taskType)
				if (this.taskType != "monthviewClassTaskEdit") {
					console.log("taskUpload1")
					this.taskForm.taskList[props.number].attPath = props.fileData
					this.taskForm.taskList[props.number].attNamq = props.fileName
					this.$forceUpdate()
				} else {
					console.log("taskUpload2")
					this.editTaskForm.attPath = props.fileData
					this.editTaskForm.attNamq = props.fileName
					this.$forceUpdate()
				}

			},
			async getTaskData(type, intParams) {
				console.log("intParams", intParams)
				this.canEdit = false
				if (type == "monthViewIcon") {
					this.taskProps = {}
					this.taskProps = intParams
					this.taskForm = {
						taskList: []
					}
					this.taskType = "monthViewsTaskAdd"
					this.diaLogTitle = "发放任务"
					let originData = await this.originData()
					// console.log("originData", originData)
					this.taskForm.taskList.push(originData)
					this.belongData(intParams)
					this.diaLogVisible = true
				} else if (type == "allViewBtn" || type == "monthViewBtn" || type == "targetDetailBtn") {
					console.log("getTaskData", type, intParams)
					// if (type != "monthViewBtn") {
					// 	this.pickerOptions = {
					// 		disabledDate: time => {
					// 			let day = 1 * 24 * 3600 * 1000 - 1
					// 			let maxTime = new Date(intParams.completeDateEnd).getTime();
					// 			let minTime = new Date(intParams.completeDateStart).getTime() - day;
					// 			return time.getTime() < minTime || time.getTime() > maxTime;
					//  	}
					// 	}
					// }
					if (intParams.handleType == "targetEdit") {
						// this.taskProps = {}
						// this.taskProps = intParams
						// this.taskType = "monthviewClassTaskEdit"
						// this.editTaskForm = this.taskProps
						// if (intParams.status == 2) {
						// 	this.canEdit = true
						// }
						// this.editTaskForm.finishEditTime = [this.taskProps.completeDateStartOld, this.taskProps
						// 	.completeDateEndOld
						// ]
						// this.diaLogTitle = `编辑任务`
						// this.diaLogVisible = true
						detailViewsTask({
							viewTaskId: intParams.viewTaskId
						}).then((res) => {
							if (res.code == 0 && res.data) {
								this.taskType = "monthviewClassTaskEdit"
								this.editTaskForm = res.data
								if (res.data.status == 2) {
									this.canEdit = true
								}
								this.editTaskForm.finishEditTime = [res.data.completeDateStart, res.data
									.completeDateEnd
								]
								this.diaLogTitle = `编辑任务`
								this.diaLogVisible = true
								this.$forceUpdate()
							}
						})
					} else if (intParams.handleType == "targetDetail") {
						this.editTaskForm = {}
						this.editTaskForm = intParams
						// console.log("editTaskForm2", this.editTaskForm)
						this.editTaskForm.finishEditTime = [intParams.completeDateStart, intParams
							.completeDateEnd
						]
						if (intParams.status == 2) {
							this.canEdit = true
						}
						this.taskType = "monthviewClassTaskEdit"
						this.diaLogTitle = `任务详情`
						this.diaLogVisible = true
					} else {
						this.taskProps = {}
						this.taskForm = {
							taskList: []
						}
						this.taskProps = intParams
						if (intParams.handleType == 'allViewsTaskAdd') {
							this.taskType = "allViewsTaskAdd"
						} else if (intParams.handleType == 'targetTaskAdd') {
							this.taskType = "targetTaskAdd"
						} else {
							this.taskType = "monthviewClassTaskAdd"
						}
						let originData = await this.originData()
						console.log("originData", originData)
						this.taskForm.taskList.push(originData)
						this.diaLogTitle = `发放任务`
						this.diaLogVisible = true
					}
				}
			},
			async addTask() {
				let originData = await this.originData()
				console.log("originData", originData)
				this.taskForm.taskList.push(originData)
			},
			closeDialog() {
				this.diaLogVisible = false
				// this.$emit('closeDialog', 'commom')
			},
			submitDialog() {
				if (this.taskType != "monthviewClassTaskEdit") {
					let isValid = true
					let validateArr = this.$refs["taskForm"]
					validateArr.forEach(item => {
						return new Promise((resolve, reject) => {
							item.validate(valid => {
								if (valid) {
									isValid = true
									resolve(isValid)
								} else {
									isValid = false
									resolve(isValid)
								}
							})
						})
					})
					if (isValid) {
						console.log("taskForm", this.taskForm)
						let params = JSON.parse(JSON.stringify(this.taskForm))
						addAllViewsTask(params.taskList).then((res) => {
							if (res.code == 0) {
								this.$message({
									message: "任务添加成功~",
									type: 'success'
								})
								if (this.taskType == "allViewsTaskAdd") {
									Bus.$emit("taskFormHandle", {
										handleType: "allViewsTaskAdd",
									})
								} else {
									Bus.$emit("taskFormHandle", {
										handleType: "monthViewsTaskAdd",
									})
								}
								this.diaLogVisible = false
							}
						})
					}
				} else {
					this.$refs["editTaskForm"].validate(valid => {
						if (valid) {
							console.log("editTaskForm", this.editTaskForm)
							let params = JSON.parse(JSON.stringify(this.editTaskForm))
							editViewTask(params).then((res) => {
								if (res.code == 0) {
									this.$message({
										message: "任务编辑成功~",
										type: 'success'
									})
									Bus.$emit("taskFormHandle", {
										handleType: "monthViewsTaskEdit",
									})
									this.$emit("taskEditSuccess")
									this.diaLogVisible = false
								}
							})
						}
					})
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";

	/deep/.el-dialog__body {
		max-height: 500px;
		overflow: hidden;
		overflow-y: auto;
	}

	/deep/.el-date-editor.el-input :focus {
		border: 1px solid $theme_color;
	}

	/deep/.el-input__inner {}

	/deep/.el-form-item__label {
		text-align: left;
	}

	input::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	textarea::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.flex_column {
		display: flex;
		flex-direction: column;
	}

	.flex_between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.handle_form {
		.handle_form_row {
			margin-top: 25px;
			display: flex;
			// padding: 0 23px;

			.handle_form_add {
				width: 143px;
				line-height: 34px;
				border: 1px solid $theme_color;
				border-radius: 2px;
				text-align: center;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: $theme_color;
				cursor: pointer;
				margin-top: 20px;
			}

			.handle_form_item {
				// flex: 1;
				display: flex;
				align-items: center;
				
				.handle_form_delete {
					font-size: 16px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 17px;
					color: red;
					margin-left: 5px;
					cursor: pointer;
				}

				.handle_form_date {
					width: 407px;
					line-height: 34px;
				}

				.handle_form_select {
					width: 200px;
				}
				
				.handle_form_upLoad {
					margin-bottom: 5px;
				}

				.handle_form_textarea {
					width: 407px;
					height: 120px;
					border: 1px solid #EEEEEE;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #000000;
					padding: 10px 10px;
					box-sizing: border-box;
					resize: none;
					margin-top: 8px;
				}

				.handle_form_ipt {
					width: 407px;
					border: 1px solid #EEEEEE;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
					padding: 0 10px;
					box-sizing: border-box;
				}

				.handle_form_upload {
					display: flex;
					flex-direction: column;

					.form_upload_box {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 128px;
						line-height: 34px;
						background: #FFFFFF;
						border: 1px solid #EEEEEE;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #000000;
						cursor: pointer;

						.form_upload_icon {
							width: 14px;
							height: 14px;
							margin-right: 10px;
						}
					}

					.form_upload_tip {
						font-size: 12px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 17px;
						color: #999999;
						margin-top: 5px;
					}
				}
			}
		}

		.handle_form_btn {
			display: flex;
			align-items: center;
			margin: 40px 0 0px 0;

			.handle_form_cancel {
				margin-left: auto;
				width: 76px;
				line-height: 34px;
				background: #FFFFFF;
				border: 1px solid $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: $theme_color;
				text-align: center;
				cursor: pointer;
				margin-right: 16px;
			}

			.handle_form_confirm {
				width: 76px;
				line-height: 34px;
				background: $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFF;
				text-align: center;
				cursor: pointer;
			}
		}
	}
</style>
