import axios from '@/utils/request'
const Api = {
	allViewsStrategy: "/api/viewStrategy/getByStudentId",
	allViewsStrategyEdit: "/api/viewStrategy/updateStrategy",
	allViewsStrategyAdd: "/api/viewStrategy/save",
	allViewsStrategyDelete: "/api/viewStrategy/delById",
	allViewsClassify: "/api/viewClass/getClassForView",
	allViewsClassifyAdd: "/api/viewClass/save",
	allViewsClassifyEdit: "/api/viewClass/updateById",
	allViewsClassifyDelete: "/api/viewClass/delById",
	allViewsTarget: "/api/viewTarget/getByStudentIdAndYear",
	allViewsTargetAdd: "/api/viewTarget/save",
	allViewsTargetDetail: "/api/viewTarget/getById",
	allViewsTargetEdit: "/api/viewTarget/updateById",
	allViewsTargetDelete: "/api/viewTarget/delById",
	allViewsTask: "/api/viewTask/getByCondition",
	allViewsTaskAdd: "/api/viewTask/saveBatch",
	viewsTargetSummary: "/api/viewTaskSummary/getByCondition",
	viewsTargetSummaryAdd: "/api/viewTaskSummary/save",
	viewsTargetSummaryEdit: "/api/viewTaskSummary/updateById",
	taskGrantHistory: "/api/viewTask/getByConditionForPage",
	viewTaskDelete: "/api/viewTask/delById",
	viewTaskEdit: "/api/viewTask/updateById",
	viewsTaskDetail: "/api/viewTask/getById",
}

// 获取总视图策略
export function strategyAllViews(params) {
	return axios({
		method: 'post',
		url: Api.allViewsStrategy,
		data: params
	})
}
// 新增总视图策略
export function addAllViewsStrategy(params) {
	return axios({
		method: 'post',
		url: Api.allViewsStrategyAdd,
		data: params
	})
}
// 删除总视图策略
export function deleteAllViewsStrategy(params) {
	return axios({
		method: 'post',
		url: Api.allViewsStrategyDelete,
		data: params
	})
}
// 编辑总视图策略
export function editAllViewsStrategy(params) {
	return axios({
		method: 'post',
		url: Api.allViewsStrategyEdit,
		data: params
	})
}
// 获取总视图分类
export function classifyAllViews(params) {
	return axios({
		method: 'post',
		url: Api.allViewsClassify,
		data: params
	})
}
// 添加总视图分类
export function addAllViewsClassify(params) {
	return axios({
		method: 'post',
		url: Api.allViewsClassifyAdd,
		data: params
	})
}
// 编辑总视图分类
export function editAllViewsClassify(params) {
	return axios({
		method: 'post',
		url: Api.allViewsClassifyEdit,
		data: params
	})
}
// 删除总视图分类
export function deleteAllViewsClassify(params) {
	return axios({
		method: 'post',
		url: Api.allViewsClassifyDelete,
		data: params
	})
}

// 获取总视图目标
export function targetAllViews(params) {
	return axios({
		method: 'post',
		url: Api.allViewsTarget,
		data: params
	})
}
// 添加总视图目标
export function addAllViewsTarget(params) {
	return axios({
		method: 'post',
		url: Api.allViewsTargetAdd,
		data: params
	})
}
// 编辑总视图目标
export function editAllViewsTarget(params) {
	return axios({
		method: 'post',
		url: Api.allViewsTargetEdit,
		data: params
	})
}
// 详情总视图目标
export function detailAllViewsTarget(params) {
	return axios({
		method: 'post',
		url: Api.allViewsTargetDetail,
		data: params
	})
}
// 删除总视图目标
export function deleteAllViewsTarget(params) {
	return axios({
		method: 'post',
		url: Api.allViewsTargetDelete,
		data: params
	})
}

// 获取总视图目标任务
export function taskAllViews(params) {
	return axios({
		method: 'post',
		url: Api.allViewsTask,
		data: params
	})
}
// 新增总视图目标任务
export function addAllViewsTask(params) {
	return axios({
		method: 'post',
		url: Api.allViewsTaskAdd,
		data: params
	})
}


// 获取既要记录
export function summaryViewsTarget(params) {
	return axios({
		method: 'post',
		url: Api.viewsTargetSummary,
		data: params
	})
}
// 新增既要记录
export function addViewsTargetSummary(params) {
	return axios({
		method: 'post',
		url: Api.viewsTargetSummaryAdd,
		data: params
	})
}
// 编辑既要记录
export function editViewsTargetSummary(params) {
	return axios({
		method: 'post',
		url: Api.viewsTargetSummaryEdit,
		data: params
	})
}
// 任务发放记录
export function historyTaskGrant(params) {
	return axios({
		method: 'post',
		url: Api.taskGrantHistory,
		data: params
	})
}
// 删除月视图任务
export function deleteViewTask(params) {
	return axios({
		method: 'post',
		url: Api.viewTaskDelete,
		data: params
	})
}
// 编辑月视图任务
export function editViewTask(params) {
	return axios({
		method: 'post',
		url: Api.viewTaskEdit,
		data: params
	})
}
// 详情月视图任务
export function detailViewsTask(params) {
	return axios({
		method: 'post',
		url: Api.viewsTaskDetail,
		data: params
	})
}
